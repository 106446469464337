<template>
  <div class="application">
    <app-bar @open="openHamburger = !openHamburger" app-page="ESS" />
    <!-- Add app-page="ESS" to app bar -->
    <side-bar v-if="openHamburger" :background-color="appearance.navigationColor || defaultNavColor">
      <div>
        <div class="sideItemContainer" v-for="(item, i) in items.filter(item => item.show === true)"
            :key="i">
          <!-- Main Item -->
          <div
            :class="[
              item.href === activeLink ? 'active-link' : 'non-active-link',
              item.href === 'EssPayslip' && !payslipPermission.canViewPayslip
                ? 'text-romanSilver'
                : 'cursor-pointer'
            ]"
            @click.prevent="
              item.children ? openChildren(item) : openData(item.href)
            "
            class="sideItem cursor-pointer font-semibold"
          >
            <div class="pr-3 pl-5 iconContainer" >
              <icon :icon-name="item.icon" size="xsm" class-name="iconSize" />
            </div>
            <div v-tooltip.top="item.text" class="textContainer">
              {{ item.text }}
            </div>
            <div
              v-if="item.children"
              @click.stop="openChildren(item)"
              class="expandIcon"
            >
              <icon :icon-name="item.model ? 'chevronDown' : 'chevronRight'" />
            </div>
          </div>

          <!-- Child Items -->
          <ul v-if="item.model" class="childItems">
            <li
              v-for="(child, index) in item.children.filter(
                child => child.show === true
              )"
              :key="index"
              :style="
                child.href === activeLink ? 'color: #F15A29' : 'color: white;'
              "
              @click.stop="$router.push({ name: child.href })"
            >
            <div class="listDotIcon"></div>
              <div v-tooltip.top="child.text" class="textContainer">
                {{ child.text }}
              </div>
            </li>
          </ul>

        </div>
      </div>
    </side-bar>
    <side-bar style="width: 70px" v-else :background-color="appearance.navigationColor || defaultNavColor">
      <div>
        <div
          v-for="(item, i) in items.filter(item => item.show === true)"
          :key="i"
          :class="[
            item.href === activeLink ? 'active-link' : 'non-active-link'
          ]"
          @click.prevent="
            item.children
              ? openChildren(item)
              : $router.push({ name: item.href })
          "
          class="text-base flex-wrap sideItem active cursor-pointer flex text-center text-white font-semibold"
        >
          <Menu
            v-if="item.children"
            @click.stop="openChildren(item)"
            top="-150"
            right
            margin="69"
            class="pr-3 pl-5"
            style="margin: auto 0"
          >
            <template v-slot:title>
              <icon :icon-name="item.icon" size="xsm" class-name="iconSize" />
            </template>
            <template v-slot:dropdownTitle>
              <p class="text-left uppercase">
                {{ item.text }}
              </p>
            </template>
            <ul style="width: 200px; padding-left: 30px">
              <li
                v-for="(child, index) in item.children.filter(
                  child => child.show === true
                )"
                :key="index"
                style
                class="text-justify mt-4 cursor-pointer"
                :style="
                  child.href === activeLink
                    ? 'color: #F15A29'
                    : 'color: #878E99;'
                "
                @click.stop="$router.push({ name: child.href })"
              >
                {{ child.text }}
              </li>
            </ul>
          </Menu>
          <div class="pr-3 pl-5" style="margin: auto 0" v-else>
            <icon :icon-name="item.icon" size="xsm" class-name="iconSize" />
          </div>
        </div>
      </div>
    </side-bar>
    <div
      class="border banner text-center text-lg font-extrabold"
      v-if="windowWidth <= 768"
      :style="
        `${
          openHamburger
            ? 'left: 220px; width:calc(100vw - 220px)'
            : 'left: 70px; width:calc(100vw - 70px)'
        }`
      "
    >
      We have detected you are using a device with a small screen to access the
      app. We recommend a device with a larger screen for a better user
      experience
    </div>
    <div
      v-if="showError"
      class="border essBanner w-2/4 text-desire text-left text-lg font-extrabold"
    >
      <span>
        <Icon class="" icon-name="icon-info" size="xsm" />
      </span>
      <span class="flex w-full">
        <span class="font-semibold flex-1 ml-4">{{ timerCount }}</span>
        <span
          @click="handleCloseTimer"
          class="cursor-pointer"
          v-if="!hasSubscriptionExpired()"
        >
          <Icon icon-name="close" class="text-black mt-1" size="xsm" />
        </span>
      </span>
    </div>
    <main
      class="pt-16"
      style="margin-bottom: 70px"
      :style="`${openHamburger ? 'margin-left: 220px' : 'margin-left: 70px'}`"
    >
      <router-view />
    </main>
    <Modal v-if="showSubModal">
      <Card class="p-5 flex flex-col max-w-3xl">
        <div class>
          <p class="font-bold my-5">
            {{
              `${showDays} left in your ${
                subStatus === "paid" ? "subscription" : "free trial"
              }`
            }}
          </p>
          <p style="white-space: pre-wrap" class="mb-6 text-justify">
            {{
              subStatus === "paid"
                ? `\nYour subscription expires on ${$DATEFORMAT(
                    new Date($store.state.expiryDate),
                    "dd/MM/yyyy"
                  )}
            and will be automatically renewed to enable you to continue to
            access your Cloudenly account.\n\nIf your payment card on file has
            expired and/or is no longer valid, please, update before your
            subscription expires to continue to have access to your Cloudenly
            account. \n\nYour new card will not be charged until your current
            subscription ends and you can cancel your subscription at any time.`
                : `\nYour free trial ends on ${$DATEFORMAT(
                    new Date($store.state.expiryDate),
                    "dd/MM/yyyy"
                  )}.\n\nPlease, upgrade to a paid subscription before your trial ends to continue to have access to Cloudenly.\n\nTo upgrade, you need to add a payment card to your account. Your new card will not be charged until your current subscription ends and you can cancel your subscription at any time.`
            }}
          </p>
          <div class="flex mb-4">
            <Button
              class="text-white bg-dynamicBackBtn mr-4"
              v-if="$AuthUser.isRoot"
              @click="handleManageSub"
            >
              Manage Subscription
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              @click="showSubModal = false"
            >
              Close
            </Button>
          </div>
        </div>
      </Card>
    </Modal>

    <div v-if="showBalloons" ref="balloonContainer" id="balloon-container">
      <div v-for="(balloon, index) in balloons" :key="index" class="balloon" :style="balloon.style">
        <div class="string"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseISO, formatDuration, intervalToDuration } from "date-fns";
import { mapState } from "vuex";
import SideBar from "@scelloo/cloudenly-ui/src/components/side-bar";
import AppBar from "@/components/appBar/appBar";
import Icon from "@/components/Icon";
import Menu from "@/components/Menu";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import Vue from "vue";

export default {
  components: {
    SideBar,
    AppBar,
    Icon,
    Menu,
    Modal,
    Button,
    Card
  },
  name: "Dasboard",
  data() {
    return {
      windowWidth: window.screen.width,
      openOptions: false,
      showError: false,
      showSubModal: false,
      openHamburger: true,
      activeLink: this.$router.currentRoute.name,
      timerCount: null,
      showBalloons: false,
      balloons: [],
      userBirthday: "2024-11-28",
    };
  },
  computed: {
    items()
    {
      const itemsData =  [
        {
          icon: "dashboard",
          text: "Dashboard",
          show: true,
          href: "EmployeeDashboard"
        },

        {
          icon: "people",
          text: "Personal Info",
          show: true,
          href: "EssPersonalInfo"
        },
        {
          icon: "message",
          text: "Payslips",
          show: true,
          href: "EssPayslip"
        },
        {
          icon: "file_todo",
          text: "Directory",
          show: true,
          href: "EssDirectiory"
        },
        {
          icon: "document",
          text: "Documents",
          show: process.env.NODE_ENV === "development",
          children: [
            {
              icon: "dot",
              text: "Organization Docs",
              href: "EssOrganisationCompanyDocuments",
              show: false,
            },
            {
              icon: "dot",
              text: "Employee Docs",
              href: "EssEmployeeDocs",
              show: false,
            }
          ],
        },
        {
          icon: "icon-sand-clock",
          text: "Leave Management",
          show: true,
          href: "EssLeaveManagement"
        },
        {
          icon: "icon-sand-clock",
          text: "Time & Attendance",
          show: process.env.NODE_ENV === "development",
          href: "EssTimeAttendance"
        },
        {
          icon: "advance_icon",
          text: "Advance & Loans",
          show: true,
          href: "EssAdvanceLoans"
        },
        {
          icon: "overtime_icon",
          text: "Overtime",
          show: true,
          href: "EssOvertime"
        },
        {
          icon: "icon-copy-white",
          text: "Manager Self-Service",
          show: this.isSupervisor,
          model: false,
          children: [
            {
              icon: "dot",
              text: "My Direct Report",
              show: true,
              href: "ManagerDirectReporting"
            },
            {
              icon: "dot",
              text: "Leave Management",
              show: true,
              href: "EssMSSLeave"
            },
            {
              icon: "dot",
              text: "Advance & Loans",
              show: true,
              href: "ManagerAdvanceLoans"
            },
            {
              icon: "dot",
              text: "Overtime",
              show: true,
              href: "ManagerOvertimeRequests"
            },
            {
              icon: "dot",
              text: "Confirmation",
              show: true,
              href: "EssMSSConfirmation"
            },
            {
              icon: "dot",
              text: "Redeployment",
              show: true,
              href: "EssMSSRedeployment"
            },
            {
              icon: "dot",
              text: "Promotions",
              show: true,
              href: "EssMSSPromotions"
            },
            {
              icon: "dot",
              text: "Delegation",
              show: true,
              href: "EssMSSDelegation"
            },
            {
              icon: "dot",
              text: "Compensation Review",
              show: process.env.NODE_ENV === "development",
              href: "EssCompensationReview"
            },
            {
              icon: "dot",
              text: "Performance",
              show: true,
              href: "EssMSSPerformance"
            },
          ]
        },
        {
          icon: "icon-approval",
          text: "Approval",
          show: true,
          model: false,
          children: [
            {
              icon: "dot",
              text: "My Requests",
              show: true,
              href: "EssMyRequests"
            },
            {
              icon: "dot",
              text: "My Approvals",
              show: true,
              href: "EssMyApprovals"
            }
          ]
        },
        {
          icon: "performance",
          text: "Performance",
          show: true,
          model: false,
          children: [
            {
              icon: "dot",
              text: "Agreements",
              show: true,
              href: "EssAgrreements"
            },
            {
              icon: "dot",
              text: "Appraisals",
              show: true,
              href: "EssAppraisals"
            },
            {
              icon: "dot",
              text: "Moderation",
              show: process.env.NODE_ENV === "development",
              href: "EssPerformanceModeration"
            },
            {
              icon: "dot",
              text: "Improvement",
              show: process.env.NODE_ENV === "development",
              href: "EssImprovement"
            }
          ]
        }
      ]
      return itemsData.map((item) => Vue.observable(item));
    },
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
    isSupervisor() {
      return this.$AuthUser?.userDesignation?.isSupervisory
    },
    ...mapState({
      payslipPermission: state => state.employee.essPermissions,
      defaultNavColor: (state) => state.orgAppearance.defaultSideNavColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    showDays() {
      const duration = intervalToDuration({
        start: new Date(),
        end: parseISO(this.$store.state.expiryDate)
      });
      return formatDuration(duration, { format: ["days"], zero: true });
    },
    subStatus() {
      return this.$store.state.subscriptionStatus;
    }
},
  methods: {
    handleManageSub(){
      return window.location.replace(
        `${process.env.VUE_APP_CLOUDENLY}subscriptions/management`
      );
    },
    onResize() {
      this.windowWidth = window.screen.width;
    },
    showSubExpiresIn7DaysModal() {
      if (
        !this.hasSubscriptionExpired() &&
        this.$AuthUser.isRoot &&
        this.$SubscriptionExpiresIn7Days()
      ) {
        this.showSubModal = true;
      } else {
        this.showSubModal = false;
      }
    },
    openData(value) {
      if (value === "EssPayslip" && !this.payslipPermission.canViewPayslip) {
        return;
      }
      this.$router.push({ name: value });
    },

    openChildren(item) {
      this.items.forEach(element => {
        const hasChildren = Object.prototype.hasOwnProperty.call(
          element,
          "children"
        );
        if (hasChildren) {
          const dropdownElement = element;
          if (item.text === dropdownElement.text) {
            dropdownElement.model = !dropdownElement.model;
            return;
          }
          dropdownElement.model = false;
        }
      });
    },
    handleCloseTimer() {
      this.showError = false;
    },
    timerCounter() {
      this.$store.commit("SET_APP_SUBSCRIPTION");
      if (!this.hasSubscriptionExpired()) {
        setTimeout(() => {
          const duration = intervalToDuration({
            start: new Date(),
            end: parseISO(this.$store.state.expiryDate)
          });
          this.timerCount = `Your Subscription will expire in ${formatDuration(
            duration,
            {
              delimiter: ", "
            }
          )}`;
          this.timerCounter();
        }, 1000);
      } else {
        this.timerCount =
          "Your company's subscription has expired. You only get READ ACCESS from now on. Please contact your Administrator";
      }
    },

    random(num) {
      return Math.floor(Math.random() * num);
    },
    getRandomStyles() {
      const r = this.random(255);
      const g = this.random(255);
      const b = this.random(255);
      const mt = this.random(200);
      const ml = this.random(50);
      const dur = this.random(5) + 5;
      return {
        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.7)`,
        color: `rgba(${r}, ${g}, ${b}, 0.7)`,
        boxShadow: `inset -7px -3px 10px rgba(${r - 10}, ${g - 10}, ${b - 10}, 0.7)`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        animation: `float ${dur}s ease-in infinite`,
      };
    },
    createBalloons(num) {
      const newBalloons = [];
      for (let i = 0; i < num; i++) {
        newBalloons.push({
          style: this.getRandomStyles(),
        });
      }
      this.balloons = newBalloons;
    },
    removeBalloons() {
      const container = this.$refs.balloonContainer;
      if (container) {
        container.style.opacity = 0;
      }
      setTimeout(() => {
        this.balloons = [];
        this.showBalloons = false
        if (container) {
          container.style.opacity = 1;
        }
      }, 500);
    },
    checkBirthday() {
      const today = new Date();
      const birthday = new Date(this.$AuthUser.dob);
      return today.getDate() === birthday.getDate() && today.getMonth() === birthday.getMonth();
    },
  },

  watch: {
    $route(to) {
      const link = to.name.toString();
      this.activeLink = link;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener('click', this.removeBalloons);

  },
  beforeMount () {
    const hasRefreshed = localStorage.getItem('hasRefreshed')
    if (hasRefreshed === 'false') {
      localStorage.setItem('hasRefreshed', 'true')
      window.location.reload()
    }
  },
  created() {
    if (this.$SubscriptionExpiresIn3Days()) {
      this.timerCounter();
      this.showError = true;
    }
    this.$store.dispatch("employee/getEssPermissions");
  },

  mounted() {
    this.$store.dispatch("employee/getEssPermissions");
    this.showSubExpiresIn7DaysModal();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if (this.checkBirthday()) {
      this.showBalloons = true
      this.createBalloons(30);
    }
    window.addEventListener('click', this.removeBalloons);
  }
};
</script>

<style>
.application {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.sideBar {
  position: relative;
}
.iconSize {
  width: 20px;
  height: 20px;
}
.active-link {
  background-color: #f15a29 !important;
  min-height: 40px;
}
.essBanner {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 6px 12px;
  position: absolute;
  margin-left: -25%;
  top: 74px;
  left: 58%;
  background-color: #fff5f5;
}
.banner {
  display: block;
  position: absolute;
  top: 65px;
  animation: blinker 2s step-start infinite;
  background-color: rgb(240, 229, 226);
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
main {
  flex: 1;
  max-height: 100%;
}
ul {
  list-style-type: disc;
  padding-left: 40px;
  margin: 0;
}
ol {
  list-style-type: decimal;
  padding-left: 14px;
  margin: 0;
}
.non-active-link:hover {
  background-color: #b9b5b52d;
}
.sideItem {
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: #ffffff;
  min-height: 40px;
  margin: 12px 5px 0px 5px;
}
.sideItemContainer {
  display: flex;
  flex-direction: column;
}

.textContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  padding-right: 15px;
}

.expandIcon {
  margin: 0px 15px 0px auto;
}
/* Styles for Child Items */

.childItems li {
  display: flex;
  align-items: center;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  cursor: pointer;
  text-overflow: ellipsis;
  margin-top: 16px;
  padding-left: 18px;
}
.listDotIcon {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-right: 10px;
}
.active:active {
  background-color: #f15a29 !important;
}
#balloon-container {
  height: 100vh;
  width: 100vw;
  padding: 1em;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  transition: opacity 500ms;
}

.balloon {
  height: 125px;
  width: 105px;
  border-radius: 75% 75% 70% 70%;
  position: relative;
}

.balloon .string {
  height: 75px;
  width: 1px;
  background-color: #fdfd96;
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  margin: auto;
}

.balloon:before {
  content: "";
  height: 75px;
  width: 1px;
  padding: 1px;
  background-color: #fdfd96;
  display: block;
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  margin: auto;
}

.balloon:after {
  content: "▲";
  text-align: center;
  display: block;
  position: absolute;
  color: inherit;
  top: 120px;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes float {
  from {
    transform: translateY(100vh);
    opacity: 1;
  }
  to {
    transform: translateY(-300vh);
    opacity: 0;
  }
}
</style>
